<template>
  <div>
    <b-card no-body class="text-center mt-1">
      <template #header>
        <b-container fluid class="w-100">
          <b-row>
            <b-col cols="8" class="text-left">
              <h4 class="mb-0">
                Database Advokat
              </h4>
            </b-col>
            <b-col cols="4" class="text-right">
              <!-- <b-badge pill variant="success">Terverifikasi</b-badge> -->
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="primary"
                @click="tambahBiodataBaru"
              >
                Tambah Biodata Baru
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </template>
      <b-card-body class="text-left mt-1 ml-2 mr-2 mb-2">
        <b-row>
          <b-col md="4">
            <b-form-group>
              <label>Nama:</label>
              <b-form-input
                v-model="selectedNamaLengkap"
                placeholder="Search"
                type="text"
                class="d-inline-block"
                @keyup.enter="onEnterClick"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>NIA:</label>
              <b-form-input
                v-model="selectedNia"
                placeholder="Search"
                type="text"
                class="d-inline-block"
                @keyup.enter="onEnterClick"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Status Keanggotaan:</label>
              <v-select
                v-if="optionsStatusKeanggotaan"
                v-model="selectedStatusKeanggotaan"
                :options="optionsStatusKeanggotaan"
                label="nama"
                :clearable="true"
                @input="filterSelected"
              />
            </b-form-group>
          </b-col>
          <!-- <b-col md="4">
            <b-form-group>
              <label>Status Permohonan:</label>
              <v-select
                v-if="optionsStatusPermohonan"
                v-model="selectedStatusPermohonan"
                :options="optionsStatusPermohonan"
                label="nama"
                :clearable="true"
                @input="filterSelected"
              />
            </b-form-group>
          </b-col> -->
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group>
              <label>Tanggal Berakhir:</label>
              <div>dari</div>
              <b-form-input
                v-model="selectedTanggalBerakhirStart"
                placeholder="2021-12-31"
                type="text"
                class="d-inline-block"
                @keyup.enter="onEnterClick"
              />
              <div>sampai</div>
              <b-form-input
                v-model="selectedTanggalBerakhirEnd"
                placeholder="2025-01-01"
                type="text"
                class="d-inline-block"
                @keyup.enter="onEnterClick"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>DPC</label>
              <v-select
                v-if="optionsDpnDpc"
                v-model="selectedDpnDpc"
                :options="optionsDpnDpc"
                label="nama"
                :clearable="true"
                @input="filterSelected"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-2 align-items-end">
          <b-col
            md="8"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="selectedPerPage"
              :options="optionsPerPage"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="filterSelected"
            />
            <label>(Found {{ pagination.totalRows }} records)</label>
          </b-col>
          <b-col md="4">
            <label>Sort by</label>
            <v-select
              v-model="selectedSortBy"
              label="nama"
              :options="optionsSortBy"
              :clearable="false"
              @input="filterSelected"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-table
              responsive
              :items="daftarBiodata"
              :fields="fields"
              :busy="isBusy"
              class="mt-2"
            >
              <template #table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle" />
                  <strong> Loading...</strong>
                </div>
              </template>
              <template #cell(id)="data">
                {{ data.value }}
              </template>
              <template #cell(biodata)="{ item }">
                <b-link
                  :to="{
                    name: 'biodata-detil',
                    params: { id: item.id },
                  }"
                  class="font-weight-bold d-block text-nowrap"
                >
                  <strong>{{ item.nama_lengkap }}</strong>
                  <h5 v-if="item.email">
                    {{ item.email }}
                  </h5>
                </b-link>
              </template>
              <template #cell(nia)="data">
                {{ data.value }}
              </template>
              <template #cell(tanggal_berakhir)="data">
                {{ data.value }}
              </template>
              <template #cell(status_keanggotaan_id)="data">
                <b-badge v-if="data.value === 1" pill variant="light-success">
                  Aktif
                </b-badge>
                <b-badge v-if="data.value === 2" pill variant="light-danger">
                  Tidak Aktif - Masa Keanggotaan Berakhir
                </b-badge>
                <b-badge v-if="data.value === 3" pill variant="light-danger">
                  Tidak Aktif - Pengakhiran
                </b-badge>
                <b-badge v-if="data.value === 4" pill variant="light-warning">
                  Advokat - Non Anggota
                </b-badge>
                <b-badge v-if="data.value === 5" pill variant="light-info">
                  Tidak Diketahui
                </b-badge>
                <b-badge v-if="data.value === 6" pill variant="light-primary">
                  Calon Advokat
                </b-badge>
                <b-badge v-if="data.value === 7" pill variant="light-dark">
                  BAS Belum Diunggah
                </b-badge>
              </template>
              <template #cell(struktur_organisasi)="data">
                {{ data.value.nama }}
              </template>
              <template #cell(action)="data">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-danger"
                  class="btn-icon rounded-circle"
                  size="sm"
                  @click="confirmDelete(data.index)"
                >
                  <feather-icon icon="DeleteIcon" />
                </b-button>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  class="btn-icon rounded-circle ml-1"
                  size="sm"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-pagination-nav
              v-if="showPagination"
              v-model="selectedPageNum"
              :link-gen="linkGen"
              :number-of-pages="pagination.numberOfPages"
              use-router
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  VBModal,
  BButton,
  BRow,
  BCol,
  BCardBody,
  BFormGroup,
  BFormInput,
  BContainer,
  BTable,
  BLink,
  BBadge,
  BPaginationNav,
  BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import moment from 'moment'
import { api, getCacheableApi } from '@/hap-sia/setup'

export default {
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BButton,
    BContainer,
    BTable,
    BLink,
    BBadge,
    BPaginationNav,
    BSpinner,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      selectedStatusKeanggotaan: null,
      optionsStatusKeanggotaan: [
        { id: 1, nama: 'Aktif' },
        { id: 2, nama: 'Tidak Aktif - Masa Keanggotaan Berakhir' },
        { id: 3, nama: 'Tidak Aktif - Pengakhiran' },
        { id: 4, nama: 'Advokat - Non Anggota' },
        { id: 5, nama: 'Tidak Diketahui' },
        { id: 6, nama: 'Calon Advokat' },
        { id: 7, nama: 'BAS Belum Diunggah' },
      ],
      optionsPerPage: [1, 3, 10, 25, 50, 100],
      selectedPerPage: 10,
      optionsSortBy: [
        {
          nama: 'Last Created',
          query: 'created_at|desc',
          id: 1,
        },
        {
          nama: 'Last Updated',
          query: 'updated_at|desc',
          id: 2,
        },
      ],
      selectedSortBy: {
        nama: 'Last Created',
        query: 'created_at|desc',
        id: 1,
      },
      fields: [
        { key: 'id', label: 'ID' },
        { key: 'biodata', label: 'Nama' },
        { key: 'nia', label: 'NIA' },
        {
          key: 'tanggal_berakhir',
          label: 'Tanggal Berakhir',
          tdClass: 'custom-cell-date',
          formatter: value => {
            moment.locale('id')
            if (!value) {
              return ''
            }
            return moment(value).format('LL')
          },
        },
        {
          key: 'status_keanggotaan_id',
          label: 'Status Keanggotaan',
          formatter: 'formatterKeanggotaan',
        },
        { key: 'struktur_organisasi', label: 'DPC' },
        {
          key: 'action',
          label: 'Action',
          tdClass: 'custom-cell-action',
          // tdAttr: {
          //   width: '600',
          // },
        },
      ],
      daftarBiodata: [
        {
          id: '123',
          dpc: 'DPC Bogor',
          nama_lengkap: 'Himawan Anindya Putra',
          nia: 'NIA-2021',
          email: 'saya@saya.com',
          tanggal_berakhir: '2021-12-31',
          status_keanggotaan_id: 1,
        },
      ],
      isBusy: false,
      selectedPageNum: 1,
      showPagination: false,
      pagination: {
        numberOfPages: 1,
        totalRows: 0,
        rowsPerPage: 0,
      },
      selectedNamaLengkap: null,
      selectedNia: null,
      currentQuery: null,
      currentQueryParam: null,
      optionsDpnDpc: [],
      selectedDpnDpc: null,
      selectedTanggalBerakhirStart: null,
      selectedTanggalBerakhirEnd: null,
    }
  },
  async mounted() {
    this.$root.fabStatus(true)

    const cacheApi = await getCacheableApi()
    const resp = await cacheApi.get('master/struktur-organisasi')

    // this.optionsDpnDpc = [{ value: null, text: '-- Pilih Kab/Kota --' }]

    resp.data.forEach(element => {
      this.optionsDpnDpc.push({
        id: element.id,
        nama: element.nama,
      })
    })
    // this.selectedSortBy = 1
    // console.log('MAPTAT')
    // console.log(this.$route.query)
    const queryParams = this.processQueryString(this.$route.query)
    this.selectedPerPage = this.$route.query.limit
      ? this.$route.query.limit
      : 10

    // const generatedQueryString = this.buildQueryString(queryParams)
    await this.executeBackend(queryParams)
    this.showPagination = true
  },
  async beforeRouteUpdate(to, from, next) {
    // console.log(to)
    const queryParams = this.processQueryString(to.query)
    // const generatedQueryString = this.buildQueryString(queryParams)
    this.executeBackend(queryParams)
    next()
  },
  methods: {
    linkGen(pageNum) {
      const queryParams = this.processQueryString(this.currentQuery)
      queryParams.page = pageNum
      const generatedQueryString = this.buildQueryString(queryParams)
      // console.log(generatedQueryString)

      return `${generatedQueryString}`
      // console.log(pageNum)
    },
    newQueryParams() {
      return {
        biodata_id: null,
        status_keanggotaan_id: null,
        nama_lengkap: null,
        nia: null,
        dpn_dpc_id: null,
        tanggal_berakhir_start: null,
        tanggal_berakhir_end: null,
        sort_by: null,
        limit: 10,
        offset: null,
        page: 1,
      }
    },
    processQueryString(queryString) {
      // this.$route.query
      // console.log('Current URL Query String')
      // console.log(queryString)
      // check apakah ada query string
      // if (Object.keys(queryString).length === 0) {
      //   alert('no query string')
      // }
      const queryParams = this.newQueryParams()
      // const paramProps = Object.keys(queryParams)
      // paramProps.forEach( prop => {
      //   queryParams[paramProps] = this.$route.query[prop] ? this.$route.query[prop] :
      // })
      const selectedStatusKeanggotaanId = this.selectedStatusKeanggotaan
        ? this.selectedStatusKeanggotaan.id
        : null
      queryParams.status_keanggotaan_id = queryString.status_keanggotaan_id
        ? queryString.status_keanggotaan_id
        : selectedStatusKeanggotaanId

      queryParams.biodata_id = queryString.biodata_id
        ? queryString.biodata_id
        : null

      // if (!this.selectedNamaLengkap) {
      //   this.selectedNamaLengkap = null
      // }
      // queryParams.nama_lengkap = queryString.nama_lengkap
      //   ? queryString.nama_lengkap
      //   : this.selectedNamaLengkap
      const selectedNamaLengkap = this.selectedNamaLengkap
        ? this.selectedNamaLengkap
        : null
      queryParams.nama_lengkap = queryString.nama_lengkap
        ? queryString.nama_lengkap
        : selectedNamaLengkap

      const selectedNia = this.selectedNia ? this.selectedNia : null
      queryParams.nia = queryString.nia ? queryString.nia : selectedNia

      const selectedTanggalBerakhirStart = this.selectedTanggalBerakhirStart
        ? this.selectedTanggalBerakhirStart
        : null
      queryParams.tanggal_berakhir_start = queryString.tanggal_berakhir_start
        ? queryString.tanggal_berakhir_start
        : selectedTanggalBerakhirStart

      const selectedTanggalBerakhirEnd = this.selectedTanggalBerakhirEnd
        ? this.selectedTanggalBerakhirEnd
        : null
      queryParams.tanggal_berakhir_end = queryString.tanggal_berakhir_end
        ? queryString.tanggal_berakhir_end
        : selectedTanggalBerakhirEnd

      queryParams.sort_by = queryString.sort_by
        ? queryString.sort_by
        : this.selectedSortBy.query
      queryParams.limit = queryString.limit
        ? queryString.limit
        : this.selectedPerPage
      queryParams.offset = queryString.offset ? queryString.offset : 0
      queryParams.page = queryString.page ? queryString.page : 1

      const selectedDpnDpcId = this.selectedDpnDpc
        ? this.selectedDpnDpc.id
        : null
      queryParams.dpn_dpc_id = queryString.dpn_dpc_id
        ? queryString.dpn_dpc_id
        : selectedDpnDpcId

      return queryParams
    },
    buildQueryString(params) {
      // console.log('Query Params')

      const queryParams = params

      // disinilah kita merubah offset disesuaikan dengan param page
      const { page } = queryParams
      // hitung offset
      if (page) {
        if (page === '1') {
          queryParams.offset = 0
        } else {
          queryParams.offset = (page - 1) * queryParams.limit
        }
      }
      const keys = Object.keys(queryParams)
      let i = 0
      let currentQuery = ''
      keys.forEach(element => {
        if (
          queryParams[element] !== null &&
          queryParams[element] !== undefined
        ) {
          // console.log('value')
          // console.log(element)
          // console.log(`${element} = ${queryParams[element]}`)
          if (i === 0) {
            currentQuery += '?'
            i += 1
          } else {
            currentQuery += '&'
          }
          currentQuery += `${element}=${queryParams[element]}`
        }
      })

      // console.log(params)
      // console.log(currentQuery)
      return currentQuery
    },
    async executeBackend(queryParams) {
      const generatedQueryString = this.buildQueryString(queryParams)
      // alert(`sending query to backend ${queryString}`)
      this.isBusy = true
      console.log(`executing ${generatedQueryString}`)
      // await new Promise(r => setTimeout(r, 2000))
      try {
        this.daftarBiodata.splice(0, this.daftarBiodata.length)
        const result = await api().get(`search/biodata${generatedQueryString}`)
        result.data.items.forEach(element => {
          this.daftarBiodata.push(element)
        })
        this.pagination.totalRows = result.data.total
        this.pagination.numberOfPages = Math.ceil(
          this.pagination.totalRows / queryParams.limit,
        )
        if (this.pagination.numberOfPages === 0) {
          this.pagination.numberOfPages = 1
        }
        if (queryParams.status_keanggotaan_id) {
          this.selectedStatusKeanggotaan = this.optionsStatusKeanggotaan.find(
            el => el.id === parseInt(queryParams.status_keanggotaan_id, 10),
          )
        }
        this.selectedNamaLengkap = queryParams.nama_lengkap
        this.selectedNia = queryParams.nia
        this.selectedTanggalBerakhirStart = queryParams.tanggal_berakhir_start
        this.selectedTanggalBerakhirEnd = queryParams.tanggal_berakhir_end

        if (queryParams.dpn_dpc_id) {
          this.selectedDpnDpc = this.optionsDpnDpc.find(
            // el => el.id === parseInt(queryParams.dpn_dpc_id, 10),
            el => el.id === queryParams.dpn_dpc_id,
          )
        }

        this.selectedPageNum = queryParams.page
        this.selectedSortBy = this.optionsSortBy.find(
          element => element.query === queryParams.sort_by,
        )
      } catch (error) {
        alert(error)
      }
      this.isBusy = false
      this.currentQuery = generatedQueryString
      this.currentQueryParam = queryParams
    },
    async filterSelected() {
      // reset query
      const queryParams = this.processQueryString({})
      const generatedQueryString = this.buildQueryString(queryParams)
      // this.executeBackend(queryParams)
      this.$router.push({
        path: `${generatedQueryString}`,
        // name: 'mandiri-list-permohonan',
        // query: { ...queryParams },
      })
    },
    confirmDelete(index) {
      // this.boxOne = ''
      this.$bvModal
        .msgBoxConfirm(
          `Are you sure deleting ${this.daftarBiodata[index].nama_lengkap}?`,
          {
            cancelVariant: 'outline-secondary',
            okTitle: 'Delete',
            okVariant: 'danger',
          },
        )
        .then(async value => {
          if (value) {
            try {
              this.isBusy = true
              const deleteBiodata = this.daftarBiodata[index]
              await api().delete(`biodata/${deleteBiodata.id}`)
              this.daftarBiodata.splice(index, 1)
            } catch (error) {
              this.makeToast(
                'danger',
                'Error',
                error.response.data.message,
                true,
              )
              this.isBusy = false
              return
            }
            this.makeToast('success', 'Sukses', 'Data berhasil dihapus')
            this.isBusy = false
          }
        })
    },
    makeToast(variant = null, title, body, noAutoHide = false) {
      this.$bvToast.toast(body, {
        title,
        variant,
        solid: true,
        autoHideDelay: 3000,
        noAutoHide,
      })
    },
    formatterKeanggotaan(value, key, item) {
      // const dataTemp = this.dataAnggota.find(testt => testt.id === value)
      const isAdvokat = item.is_advokat
      const isPengakhiran = item.is_pengakhiran
      const nia = item.nia ? item.nia : null
      const tanggalBerakhir = item.tanggal_berakhir
        ? moment(item.tanggal_berakhir).format('YYYY-MM-DD')
        : item.tanggal_berakhir
      const today = moment(new Date()).format('YYYY-MM-DD')
      let returnValue

      if (
        // Aktif
        isAdvokat === true &&
        (nia !== null || nia !== '') &&
        tanggalBerakhir !== null &&
        tanggalBerakhir !== '' &&
        tanggalBerakhir > today &&
        (isPengakhiran === false || isPengakhiran === null)
      ) {
        returnValue = 1
      }
      if (
        // Tidak Aktif - Habis Masa Berlaku
        isAdvokat === true &&
        (nia !== null || nia !== '') &&
        tanggalBerakhir !== null &&
        tanggalBerakhir !== '' &&
        tanggalBerakhir < today &&
        (isPengakhiran === false || isPengakhiran === null)
      ) {
        returnValue = 2
      }
      if (
        // Tidak Aktif - Pengakhiran
        isAdvokat === true &&
        (nia !== null || nia !== '') &&
        isPengakhiran === true
      ) {
        returnValue = 3
      }
      if (
        // Advokat - Non Anggota
        isAdvokat === true &&
        // && nia === null
        (nia === null || nia === '')
      ) {
        returnValue = 4
      }
      if (
        // Tidak Diketahui
        isAdvokat === true &&
        nia !== null &&
        nia !== '' &&
        (isPengakhiran === null || isPengakhiran === false) &&
        (tanggalBerakhir === null || tanggalBerakhir === '')
      ) {
        returnValue = 5
      }
      if (
        // Calon Advokat
        (isAdvokat === null || isAdvokat === false) &&
        (nia === null || nia === '')
      ) {
        returnValue = 6
      }
      if (
        // BAS Belum Diunggah
        (isAdvokat === null || isAdvokat === false) &&
        nia !== null &&
        nia !== ''
      ) {
        returnValue = 7
      }

      return returnValue
    },
    onEnterClick() {
      this.filterSelected()
    },
    tambahBiodataBaru() {
      this.$router.push({
        name: 'biodata-new',
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style>
.custom-cell-date {
  min-width: 200px;
  /* color: green;
  background-color: #00ff88; */
}
.custom-cell-action {
  min-width: 140px;
  /* color: green;
  background-color: #00ff88; */
}
</style>
